<template>
    <div class="resources" v-if="this.data && this.filters" data-aos="fade">
        <control-panel :data="this.data"/>
        <div class="resources__filters">
            <filter-toggle :filterName="'Categorie varsta'" :filter="this.filters.ages" @addFilter="filterBy($event, 'age_group_id')"/>
            <filter-toggle :filterName="'Colectie'" :filter="this.filters.collections" @addFilter="filterBy($event, 'collection_id')"/>
            <filter-toggle :filterName="'Tip document'" :filter="this.filters.types" @addFilter="filterBy($event, 'type_id')"/>
        </div>
        <div class="resources__divider"/>     
        <div class="resources__steps">
            <steps :data="this.data.levels" :carouselItems="3"/>
        </div>           
    </div>
</template>
<script>
import ControlPanel from '../../components/organisms/ControlPanel.vue'
import FilterToggle from '../../components/molecules/FilterToggle.vue';
import Step from '../../components/organisms/Step.vue';
export default {
    components:{
        'control-panel':ControlPanel,
        'filter-toggle':FilterToggle,
        'steps': Step
    },
    data(){
        return {
            data:null,
            filters:null,
            params:{
                age_group_id:null,
                collection_id:null,
                type_id:null,
            }
        }
    },
    mounted(){
        this.getResources();
        this.getFilters();
    },
    methods: {
        getResources(params){
            this.$axios.get('/resource-categories/'+this.$route.params.resources_id,{ params:params })
                .then(res=>{
                    this.data = res.data;
                })
                .catch(err=>{
                    this.$toasted.error(err)
                })
        },
        getFilters(){
            var self = this;
            function types(){
                return self.$axios.get('/resource-types');
            }

            function collections(){
                return self.$axios.get('/resource-collections');
            }

            function ageGroups(){
                 return self.$axios.get('/resource-age-groups');
            }

            Promise.all([types(), collections(), ageGroups()])
                    .then(res=>{
                        this.filters = {};
                        this.filters.types = res[0].data;
                        this.filters.collections = res[1].data;
                        this.filters.ages = res[2].data;
                    });

        },
        filterBy(id,property){
            this.params[property] = id;
            for(const key in this.params){
                if (this.params[key] === null){
                    delete this.params[key];
                }
            } 
            this.getResources(this.params) 
        },
    }
}
</script>